import React, { useState } from 'react'
import { DexScreenerEmbedWrapper, IframeWrapper, StyledButton, ButtonContainer } from './DexScreenerEmbed.styles'
import { CHAIN_PARAMS, NETWORK_LABEL } from 'config/constants/chains'

const DexScreenerEmbed: React.FC = () => {
  const BSC_CHAIN_ID = '56' // Replace with the actual BSC Chain ID
  const MAINNET_CHAIN_ID = '1' // Replace with the actual Mainnet Chain ID

  const [chainId, setChainId] = useState(BSC_CHAIN_ID)

  const iframeSrc =
    chainId === BSC_CHAIN_ID
      ? 'https://dexscreener.com/bsc/0x174Bf6A4FaBaE7408831fb051aDd3908807ff1a3?embed=1&theme=dark&info=0'
      : 'https://dexscreener.com/ethereum/0xEac2be5EED795D63f69C1165e87030d647E21976?embed=1&theme=dark&info=0'

  const handleChainSwitch = (newChainId: string) => {
    setChainId(newChainId)
  }

  return (
    <DexScreenerEmbedWrapper>
      <ButtonContainer>
        <StyledButton onClick={() => handleChainSwitch(BSC_CHAIN_ID)}>
          CHART Switch to {NETWORK_LABEL[BSC_CHAIN_ID]}
        </StyledButton>
        <StyledButton onClick={() => handleChainSwitch(MAINNET_CHAIN_ID)}>
          Chart Switch to {NETWORK_LABEL[MAINNET_CHAIN_ID]}
        </StyledButton>
      </ButtonContainer>
      <IframeWrapper>
        <iframe src={iframeSrc} title="DexScreener Chart" />
      </IframeWrapper>
    </DexScreenerEmbedWrapper>
  )
}

export default DexScreenerEmbed
