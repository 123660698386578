import { Values } from './types'
import { ContextApi } from '../../../../contexts/Localization/types'

export const defaultValues: (t: ContextApi['t']) => Values[] = (t) => [
  {
    title: t('Innovative'),
    description: t(
      'We create tools for users to leverage DeFi opportunities, regardless of location, background, wealth, or experience.',
    ),
    logoImg: 'images/accessibility.png',
    link: 'https://docs.foxchain.app/guide/',
  },
  {
    title: t('Fast Transaction'),
    description: t(
      'One of the key elements of this vision is to continue to improve the performance of the Foxchain platform',
    ),
    logoImg: 'images/transparency.png',
    link: 'https://docs.foxchain.app/guide/foxchain-vision',
  },
  {
    title: t('Security'),
    description: t(
      'the long-term vision for Foxchain is to create a platform that is fast, secure, and highly scalable, and that can support a wide range of applications and use case',
    ),
    logoImg: 'images/security.png',
    link: 'https://docs.foxchain.app/guide/foxchain-security',
  },
]
