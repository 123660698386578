/** @jsxImportSource theme-ui */
import React from 'react'
import { styles } from './styles'
import { Button, Flex, Text } from '@fox.swap/uikit'
import { Link } from 'react-router-dom'
import Bnb from './grayChains/bnb'
import Poly from './grayChains/poly'
import { Box, Image } from 'theme-ui'
import { useTranslation } from 'contexts/Localization'

const FoxChainV3 = () => {
  const { t } = useTranslation()

  return (
    <Flex sx={styles.slideContainer}>
      <Flex sx={styles.slideContent}>
        <Text sx={{ ...styles.slideTitle, fontSize: ['43px', '38px', '64px'] }}>{t('Add Your Liquidity')}</Text>
        <Text sx={styles.slideSubtitle}>{t('on Foxchain Router Liquidity Reward Investing For Future.')}</Text>
        <Flex sx={{ alignItems: 'center', marginTop: ['25px', '25px', '0px'] }}>
          <Text sx={styles.availableOn}>{t('AVAILABLE ON')}</Text>
          <Bnb
            sx={{ marginRight: '10px', cursor: 'pointer' }}
            onClick={() => window.open('https://foxdex.finance/add-liquidity?chain=56')}
          />
          {/* <Poly
            sx={{ marginRight: '10px', cursor: 'pointer' }}
            onClick={() => window.open('https://dexs.foxchain.app/add-liquidity?chain=137')}
          /> */}
        </Flex>
        <Link to="/add-liquidity">
          <Flex sx={styles.billImage}>
            <Box
              sx={{
                ...styles.image,
                backgroundImage: `url('https://pbs.twimg.com/media/Fs7mlc3aEAIvihJ?format=jpg&name=large')`,
              }}
            />
          </Flex>
        </Link>
        <Flex sx={styles.buttonContainer}>
          <Button
            variant="secondary"
            sx={{ ...styles.learnMoreButton }}
            onClick={() => window.open('https://docs.foxchain.app', '_blank')}
          >
            {t('Learn more')}
          </Button>
          <Button
            sx={{ fontSize: ['14px', '14px', '16px'], minWidth: ['140px', '140px'] }}
            onClick={() => window.open('https://foxdex.finance/add-liquidity')}
          >
            {t('Add liquidity')}
          </Button>
        </Flex>
      </Flex>
      <Flex sx={{ width: ['0', '100%'], justifyContent: 'center' }}>
        <Flex
          sx={{ ...styles.imageWrapper, background: 'none' }}
          onClick={() => window.open('https://dexs.foxchain.app')}
        >
          <Image src={`https://pbs.twimg.com/media/Fs7mlc3aEAIvihJ?format=jpg&name=large`} sx={styles.image} />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default FoxChainV3
