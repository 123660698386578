import sousChefABI from 'config/abi/sousChef.json'
import foxlayerABI from 'config/abi/foxlayer.json'
import { Pool, TokenPrices } from 'state/types'
import masterchefV2ABI from 'config/abi/masterChefV2.json'
import { chunk } from 'lodash'
import multicall from 'utils/multicall'
import fetchPoolCalls, { fetchFoxlayerPoolCall } from './fetchPoolCalls'
import cleanPoolData from './cleanPoolData'
import BigNumber from 'bignumber.js'
import { BLOCKS_PER_YEAR, BSC_BLOCK_TIME } from 'config'
import { ethers } from 'ethers'

const fetchPools = async (chainId: number, tokenPrices: TokenPrices[], poolsConfig: Pool[]) => {
  const poolIds = []
  const poolCalls = poolsConfig.flatMap((pool) => {
    poolIds.push(pool.sousId)
    return fetchPoolCalls(pool, chainId)
  })
  const foxlayerPoolCalls = fetchFoxlayerPoolCall(chainId)
  const vals = await multicall(chainId, [...sousChefABI, ...foxlayerABI], poolCalls)
  const [foxlayerPoolVals, totalAlloc, foxlayerPerSecond] = await multicall(chainId, masterchefV2ABI, foxlayerPoolCalls)
  // We do not want the block time for the foxlayer earn foxlayer pool so we append two null values to keep the chunks even
  // First null values is for Master Ape V2 and second is Master Ape V1
  const formattedVals = [null, null, foxlayerPoolVals?.totalStaked._hex, null, null, ...vals.slice(1)]
  const chunkSize = formattedVals.length / poolsConfig.length
  const foxlayerAlloc = foxlayerPoolVals?.allocPoint._hex
  const poolWeight = new BigNumber(foxlayerAlloc).div(new BigNumber(totalAlloc))
  const chunkedPools = chunk(formattedVals, chunkSize)
  const foxlayerPerYear = new BigNumber(ethers.utils.formatEther(foxlayerPerSecond.toString()))
    .times(BSC_BLOCK_TIME)
    .times(BLOCKS_PER_YEAR)
  return cleanPoolData(poolIds, chunkedPools, tokenPrices, chainId, poolsConfig, poolWeight, foxlayerPerYear)
}

export default fetchPools
