import styled from 'styled-components'

const StyledButton = styled.button`
  background-color: #1e88e5;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 5px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  &:hover {
    background-color: #1565c0;
  }
`

const DexScreenerEmbedWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 550px;
  width: 100%;
  padding-bottom: 125%;
  @media (min-width: 1400px) {
    padding-bottom: 65%;
  }
`

const ButtonContainer = styled.div`
  padding: 10px;
  z-index: 1;
`

const IframeWrapper = styled.div`
  position: absolute;
  top: 25%;
  left: 10%;
  width: 80%;
  height: 80%;
  border: 0;
  & iframe {
    width: 100%;
    height: 100%;
    border: 0;
  }
`
export { DexScreenerEmbedWrapper, IframeWrapper, StyledButton, ButtonContainer }
