import { MenuEntry } from '@foxswapfinance/uikit'
import { ContextApi } from '../../../contexts/Localization/types'

const maticConfig: (t: ContextApi['t']) => MenuEntry[] = (t) => [
  {
    label: t('Exchange'),
    lightIcon: 'ExchangeDarkImage',
    darkIcon: 'ExchangeDarkImage',
    items: [
      {
        label: t('Swap'),
        href: '/swap',
        isNew: false,
      },
      // {
      //   label: t('Orders'),
      //   href: '/limit-orders',
      //   isNew: false,
      // },
      {
        label: t('Liquidity'),
        href: '/add-liquidity',
        isNew: false,
      },
      // {
      //   label: t('Pro Trading'),
      //   href: 'https://pro.apeswap.finance',
      //   isNew: false,
      // },
      // {
      //   label: t('GLAYER'),
      //   href: '/glayer',
      //   isNew: false,
      // },
    ],
  },
  {
    label: t('Stake'),
    lightIcon: 'StakeLightImage',
    darkIcon: 'StakeDarkImage',
    items: [
      // {
      //   label: t('Migrate'),
      //   href: '/' + CURRENT_MIGRATE_PATH,
      //   isNew: true,
      // },
      {
        label: t('Fox Stake'),
        href: '/pools',
        isNew: false,
      },
      // {
      //   label: t('FOXCHAIN Maximizers'),
      //   href: '/maximizers',
      //   isNew: false,
      // },
      // {
      //   label: t('FOX Farming'),
      //   href: '/farms',
      //   isNew: false,
      // },
      // {
      //   label: t('Jungle Farms'),
      //   href: '/jungle-farms',
      //   isNew: false,
      // },
    ],
  },
  {
    label: t('Info'),
    lightIcon: 'ExchangeDarkImage',
    darkIcon: 'ExchangeDarkImage',
    items: [
      {
        label: t('Foxchain Explorer Testnet'),
        href: 'https://testnet.foxchain.app',
        isNew: false,
      },
      {
        label: t('Faucet Testnet'),
        href: 'https://faucet.foxchain.app',
        isNew: false,
      },
      // {
      //   label: t('Info Analitycs'),
      //   href: 'https://analitycs.foxchain.app',
      //   isNew: false,
      // },
      {
        label: t('FoxStats'),
        href: '/foxstats',
        isNew: false,
      },
    ],
  },
  {
    label: t('Tracker Data'),
    href: 'https://tracker.foxdex.finance',
    isNew: false,
  },
]

export default maticConfig
