/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getTokenUsdPrice } from 'utils/getTokenUsdPrice'
import { getFoxlayerAddress } from 'utils/addressHelper'
import fetchPrices from './fetchPrices'
import { TokenPricesState, TokenPrices, AppThunk } from '../types'
import { tokens } from '@fox.swap/foxswap-lists'

const initialState: TokenPricesState = {
  isTokensInitialized: false,
  isInitialized: false,
  isLoading: true,
  tokens: Object.values(tokens),
  foxlayerPrice: null,
  data: null,
}

export const tokenPricesSlice = createSlice({
  name: 'tokenPrices',
  initialState,
  reducers: {
    tokenPricesFetchStart: (state) => {
      state.isLoading = true
    },
    setFoxlayerPrice: (state, action) => {
      state.foxlayerPrice = action.payload
      state.isInitialized = true
    },
    tokenPricesFetchSucceeded: (state, action: PayloadAction<TokenPrices[]>) => {
      state.data = action.payload
      state.isLoading = false
      state.isInitialized = true
    },
    tokenPricesFetchFailed: (state) => {
      state.isLoading = false
      state.isInitialized = true
    },
  },
})

// Actions
export const { setFoxlayerPrice, tokenPricesFetchStart, tokenPricesFetchSucceeded, tokenPricesFetchFailed } =
  tokenPricesSlice.actions

export const fetchTokenPrices =
  (chainId, tokens): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(tokenPricesFetchStart())
      const tokenPrices = await fetchPrices(chainId, tokens)
      dispatch(tokenPricesFetchSucceeded(tokenPrices))
    } catch (error) {
      dispatch(tokenPricesFetchFailed())
    }
  }

export const fetchFoxlayerPrice =
  (chainId): AppThunk =>
  async (dispatch) => {
    try {
      const foxlayerPrice = await getTokenUsdPrice(chainId, getFoxlayerAddress(chainId), 18)
      dispatch(setFoxlayerPrice(foxlayerPrice))
    } catch {}
  }

export default tokenPricesSlice.reducer
