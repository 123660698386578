import erc20 from 'config/abi/erc20.json'
import masterchefABI from 'config/abi/masterchef.json'
import { FarmLpAprsType, TokenPrices, Vault } from 'state/types'
import { getContract } from 'utils/getContract'
import { getVaultFoxAddressV2, getVaultFoxAddressV3 } from 'utils/addressHelper'
import { chunk } from 'lodash'
import vaultFoxV2ABI from 'config/abi/vaultFoxV2.json'
import multicall from 'utils/multicall'
import fetchVaultCalls from './fetchVaultCalls'
import cleanVaultData from './cleanVaultData'

const fetchVaults = async (
  chainId: number,
  tokenPrices: TokenPrices[],
  farmLpAprs: FarmLpAprsType,
  vaultsConfig: Vault[],
) => {
  const filteredVaults = vaultsConfig.filter((vault) => vault.availableChains.includes(chainId))
  const masterVaultFoxv2 = getContract(vaultFoxV2ABI, getVaultFoxAddressV2(chainId), chainId)
  const masterVaultFoxV3 = getContract(vaultFoxV2ABI, getVaultFoxAddressV3(chainId), chainId)
  const vaultIds = []
  const vaultCalls = filteredVaults.flatMap((vault) => {
    vaultIds.push(vault.id)
    return fetchVaultCalls(vault, chainId)
  })
  const vals = await multicall(chainId, [...masterchefABI, ...erc20], vaultCalls)
  const vaultSettingsV2 = await masterVaultFoxv2.getSettings()
  const vaultSettingsV3 = await masterVaultFoxV3.getSettings()
  const chunkSize = vaultCalls.length / filteredVaults.length
  const chunkedVaults = chunk(vals, chunkSize)
  return cleanVaultData(
    vaultIds,
    chunkedVaults,
    vaultSettingsV2,
    vaultSettingsV3,
    tokenPrices,
    farmLpAprs,
    chainId,
    vaultsConfig,
  )
}

export default fetchVaults
