import { SmartRouter } from '@fox.swap/sdk'
import { SMART_PRICE_GETTERS } from 'config/constants/chains'
import addresses from 'config/constants/contracts'

export const getFoxlayerAddress = (chainId: number): string => {
  return addresses.foxlayer[chainId]
}
export const getGoldenFoxlayerAddress = (chainId: number) => {
  return addresses.goldenFoxlayer[chainId]
}
export const getTreasuryAddress = (chainId: number) => {
  return addresses.treasury[chainId]
}
export const getSyrupAddress = (chainId: number) => {
  return addresses.syrup[chainId]
}
export const getMasterChefAddress = (chainId: number) => {
  return addresses.masterChef[chainId]
}
export const getMulticallAddress = (chainId: number) => {
  return addresses.mulltiCall[chainId]
}
export const getWbnbAddress = (chainId: number) => {
  return addresses.wbnb[chainId]
}
export const getLotteryAddress = (chainId: number) => {
  return addresses.lottery[chainId]
}
export const getLotteryTicketAddress = (chainId: number) => {
  return addresses.lotteryNFT[chainId]
}
export const getFoxlayerProfileAddress = (chainId: number) => {
  return addresses.foxlayerProfile[chainId]
}
export const getNonFungibleFoxsAddress = (chainId: number) => {
  return addresses.nonFungibleFoxs[chainId]
}
export const getNonFungibleFoxlayersAddress = (chainId: number) => {
  return addresses.nonFungibleFoxlayers[chainId]
}
export const getRabbitMintingFarmAddress = (chainId: number) => {
  return addresses.rabbitMintingFarm[chainId]
}
export const getClaimRefundAddress = (chainId: number) => {
  return addresses.claimRefund[chainId]
}
export const getAuctionAddress = (chainId: number) => {
  return addresses.auction[chainId]
}
export const getFoxPriceGetterAddress = (chainId: number) => {
  return addresses.foxPriceGetter[chainId]
}
export const getSmartPriceGetter = (chainId: number, smartRouter?: SmartRouter) => {
  return SMART_PRICE_GETTERS[chainId][smartRouter || SmartRouter.FOX]
}
export const getVaultFoxAddressV1 = (chainId: number) => {
  return addresses.vaultFoxV1[chainId]
}
export const getVaultFoxAddressV2 = (chainId: number) => {
  return addresses.vaultFoxV2[chainId]
}
export const getVaultFoxAddressV3 = (chainId: number) => {
  return addresses.vaultFoxV3[chainId]
}
export const getMiniChefAddress = (chainId: number) => {
  return addresses.miniFoxV2[chainId]
}
export const getNativeWrappedAddress = (chainId: number) => {
  return addresses.nativeWrapped[chainId]
}
export const getIazoExposerAddress = (chainId: number) => {
  return addresses.iazoExposer[chainId]
}
export const getIazoSettingsAddress = (chainId: number) => {
  return addresses.iazoSettings[chainId]
}
export const getIazoFactoryAddress = (chainId: number) => {
  return addresses.iazoFactoryProxy[chainId]
}
export const getMigratorBalanceCheckerAddress = (chainId: number) => {
  return addresses.migratorBalanceChecker[chainId]
}
export const getMasterChefV2Address = (chainId: number) => {
  return addresses.masterChefV2[chainId]
}
