/** @jsxImportSource theme-ui */
import React from 'react'
import { Modal, ModalProps } from '@fox.swap/uikit'
import Glayer from './Glayer'
import { modalProps } from './styles'

const GlayerModal: React.FC<ModalProps> = ({ onDismiss }) => {
  return (
    <Modal zIndex={10} title="Get GLAYER" onDismiss={onDismiss} {...modalProps}>
      <Glayer />
    </Modal>
  )
}

export default GlayerModal
