import { MenuEntry } from '@fox.swap/uikit'
import { ContextApi } from 'contexts/Localization/types'

export const CURRENT_MIGRATE_PATH = 'the-migration'

const bscConfig: (t: ContextApi['t']) => MenuEntry[] = (t) => [
  {
    label: t('Exchange'),
    lightIcon: 'ExchangeDarkImage',
    darkIcon: 'ExchangeDarkImage',
    items: [
      {
        label: t('Swap'),
        href: '/swap',
        isNew: false,
      },
      // {
      //   label: t('Orders'),
      //   href: '/limit-orders',
      //   isNew: false,
      // },
      {
        label: t('Liquidity'),
        href: '/add-liquidity',
        isNew: false,
      },
      // {
      //   label: t('Pro Trading'),
      //   href: 'https://pro.apeswap.finance',
      //   isNew: false,
      // },
      // {
      //   label: t('GLAYER'),
      //   href: '/glayer',
      //   isNew: false,
      // },
    ],
  },
  {
    label: t('Stake'),
    lightIcon: 'StakeLightImage',
    darkIcon: 'StakeDarkImage',
    items: [
      // {
      //   label: t('Migrate'),
      //   href: '/' + CURRENT_MIGRATE_PATH,
      //   isNew: true,
      // },
      {
        label: t('Fox Stake'),
        href: '/pools',
        isNew: false,
      },
      // {
      //   label: t('FOXCHAIN Maximizers'),
      //   href: '/maximizers',
      //   isNew: false,
      // },
      {
        label: t('FOX Farming'),
        href: '/farms',
        isNew: false,
      },
      // {
      //   label: t('Jungle Farms'),
      //   href: '/jungle-farms',
      //   isNew: false,
      // },
    ],
  },
  // {
  //   label: t('Raise'),
  //   lightIcon: 'OfferingsLightImage',
  //   darkIcon: 'OfferingsDarkImage',
  //   items: [
  //     {
  //       label: t('Treasury Bills'),
  //       href: '/treasury-bills',
  //       isNew: false,
  //     },
  //     {
  //       label: t('Official IAO'),
  //       href: '/iao',
  //       isNew: false,
  //     },
  //   ],
  // },
  {
    label: t('Info'),
    lightIcon: 'ExchangeDarkImage',
    darkIcon: 'ExchangeDarkImage',
    items: [
      {
        label: t('Foxchain Explorer Testnet'),
        href: 'https://testnet.foxchain.app',
        isNew: false,
      },
      {
        label: t('Faucet Testnet'),
        href: 'https://faucet.foxchain.app',
        isNew: false,
      },
      // {
      //   label: t('Info Analitycs'),
      //   href: 'https://analitycs.foxchain.app',
      //   isNew: false,
      // },
      {
        label: t('FoxStats'),
        href: '/foxstats',
        isNew: false,
      },
    ],
  },
  {
    label: t('Tracker Data'),
    href: 'https://tracker.foxdex.finance',
    isNew: false,
  },
  // {
  //   label: t('Lend'),
  //   href: 'https://lending.apeswap.finance/',
  //   isNew: false,
  // },
  // {
  //   label: t('Explore'),
  //   lightIcon: 'MoreLightImage',
  //   darkIcon: 'MoreDarkImage',
  //   items: [
  //     {
  //       label: t('FoxStats'),
  //       href: '/foxstats',
  //       isNew: false,
  //     },
  //     {
  //       label: t('Dashboard'),
  //       href: 'protocol-dashboard',
  //       isNew: false,
  //     },
  //     {
  //       label: t('Documentation'),
  //       href: 'https://docs.foxchain.app/foxchain-dex/',
  //       isNew: false,
  //     },
  //     {
  //       label: t('Charts'),
  //       href: '/info',
  //       isNew: false,
  //     },
  //     {
  //       label: t('Governance'),
  //       href: 'https://discuss.apeswap.finance',
  //       isNew: false,
  //     },
  //     {
  //       label: t('Newsletter'),
  //       href: '?modal=newsletter',
  //       isNew: true,
  //     },
  //   ],
  // },
]

export default bscConfig
