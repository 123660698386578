import { Currency, SmartRouter, Token } from '@fox.swap/sdk'
import { Contract } from 'ethers'
import { useSingleCallResult } from 'lib/hooks/multicall'
import store from 'state'
import { getNativeWrappedAddress, getSmartPriceGetter } from 'utils/addressHelper'
import foxPriceGetter from 'config/abi/foxPriceGetter.json'
import { getBalanceNumber } from 'utils/formatBalance'

export const useTokenPriceUsd = (chainId: number, currency: Currency, lp = false, smartRouter?: SmartRouter) => {
  const isNative = currency?.symbol === 'ETH'
  const [address] = currency instanceof Token ? [currency?.address, currency?.decimals] : ['', 18]
  const priceGetterAddress = getSmartPriceGetter(chainId, smartRouter)
  const priceGetterContract = new Contract(priceGetterAddress, foxPriceGetter)

  const nativeTokenAddress = getNativeWrappedAddress(chainId)

  const { result } = useSingleCallResult(currency ? priceGetterContract : undefined, lp ? 'getLPPrice' : 'getPrice', [
    isNative ? nativeTokenAddress : address,
    18,
  ])
  if (currency?.symbol === 'GLAYER') {
    return parseFloat(store.getState().tokenPrices.foxlayerPrice) * 1.3889
  }
  return result?.[0] ? getBalanceNumber(result[0].toString(), 18) : 0
}
