import { MenuEntry } from '@fox.swap/uikit'
import { ContextApi } from '../../../contexts/Localization/types'

const arbitrumConfig: (t: ContextApi['t']) => MenuEntry[] = (t) => [
  // {
  //   label: t('Exchange'),
  //   lightIcon: 'ExchangeLightImage',
  //   darkIcon: 'ExchangeDarkImage',
  //   items: [
  //     {
  //       label: t('Swap'),
  //       href: '/swap',
  //       isNew: false,
  //     },
  //     {
  //       label: t('Liquidity'),
  //       href: '/zap',
  //       isNew: false,
  //     },
  //     {
  //       label: t('Pro Trading'),
  //       href: 'https://pro.foxdex.finance',
  //       isNew: false,
  //     },
  //   ],
  // },
  // {
  //   label: t('Bonds'),
  //   href: '/bonds',
  //   isNew: false,
  // },
  // {
  //   label: t('Explore'),
  //   lightIcon: 'MoreLightImage',
  //   darkIcon: 'MoreDarkImage',
  //   items: [
  //     {
  //       label: t('FoxStats'),
  //       href: '/foxstats',
  //       isNew: false,
  //     },
  //     {
  //       label: t('Dashboard'),
  //       href: 'protocol-dashboard',
  //       isNew: false,
  //     },
  //     {
  //       label: t('Documentation'),
  //       href: 'https://docs.foxchain.app/foxswap-finance/',
  //       isNew: false,
  //     },
  //     {
  //       label: t('Charts'),
  //       href: '/info',
  //       isNew: false,
  //     },
  //     {
  //       label: t('Governance'),
  //       href: 'https://discuss.foxdex.finance',
  //       isNew: false,
  //     },
  //     {
  //       label: t('Newsletter'),
  //       href: '?modal=newsletter',
  //       isNew: true,
  //     },
  //   ],
  // },
]

export default arbitrumConfig
