import { ChainId } from '@fox.swap/sdk'

export const MetamaskLinks = {
  [ChainId.BSC]: 'https://docs.bnbchain.org/docs/wallet/metamask/',
  [ChainId.MATIC]: 'https://wiki.polygon.technology/docs/develop/metamask/config-polygon-on-metamask/',
  [ChainId.MAINNET]: 'https://metamask.io/faqs/',
  [ChainId.CORE]: 'https://decentralizedcreator.com/add-core-evm-network-to-metamask/',
  [ChainId.ARBITRUM]: 'https://academy.binance.com/en/articles/how-to-add-arbitrum-to-metamask',
}

export const routeNames: Record<
  | '/'
  | '/jungle-farms'
  | '/foxlayer-farms'
  | '/farms'
  | '/swap'
  | '/pools'
  | '/maximizers'
  | '/add'
  | '/add-liquidity'
  | '/liquidity'
  | '/zap'
  | '/migrate'
  | '/find'
  | '/remove'
  | '/limit-orders'
  | '/iao'
  | '/glayer'
  | '/bonds'
  | '/nft'
  | '/auction'
  | '/staking'
  | '/foxstats'
  | '/protocol-dashboard'
  | '/the-migration',
  string
> = {
  '/': 'HOME',
  '/jungle-farms': 'JUNGLE_FARMS',
  '/foxlayer-farms': 'FARMS',
  '/farms': 'FARMS',
  '/swap': 'SWAP',
  '/pools': 'POOLS',
  '/maximizers': 'MAXIMIZERS_VAULTS',
  '/add': 'ADD_LIQUIDITY',
  '/add-liquidity': 'ADD_LIQUIDITY',
  '/liquidity': 'LIQUIDITY',
  '/zap': 'LIQUIDITY',
  '/migrate': 'LIQUIDITY',
  '/find': 'LIQUIDITY',
  '/remove': 'LIQUIDITY',
  '/limit-orders': 'ORDERS',
  '/iao': 'IAO',
  '/glayer': 'GLAYER',
  '/bonds': 'FOXSWAP_BONDS',
  '/nft': 'NFT',
  '/auction': 'AUCTION',
  '/staking': 'STAKING',
  '/foxstats': 'FOXSTATS',
  '/protocol-dashboard': 'PROTOCOL_DASHBOARD',
  '/the-migration': 'THE_MIGRATION',
}

export enum Farms {
  BNB = 'bnb-chain-farms',
  Polygon = 'polygon-farms',
  Ethereum = '',
  Core = 'core-farms',
}

export const DOC_LINKS = {
  HOME: 'https://docs.foxchain.app/foxswap-finance/welcome/master',
  JUNGLE_FARMS: 'https://docs.foxchain.app/foxswap-finance/product-and-features/stake/farms/',
  FARMS: `https://docs.foxchain.app/foxswap-finance/product-and-features/stake/farms/`,
  SWAP: 'https://docs.foxchain.app/foxswap-finance/product-and-features/exchange/swap',
  POOLS: 'https://docs.foxchain.app/foxswap-finance/product-and-features/stake/pools',
  MAXIMIZERS_VAULTS: 'https://docs.foxchain.app/foxswap-finance/product-and-features/stake/vaults',
  ADD_LIQUIDITY:
    'https://docs.foxchain.app/foxswap-finance/product-and-features/exchange/liquidity/how-to-add-liquidity',
  LIQUIDITY: 'https://docs.foxchain.app/foxswap-finance/product-and-features/exchange/liquidity',
  ORDERS: 'https://docs.foxchain.app/foxswap-finance/product-and-features/exchange/limit-orders',
  IAO: 'https://docs.foxchain.app/foxswap-finance/product-and-features/raise/initial-fox-offerings-iaos',
  GLAYER: 'https://docs.foxchain.app/foxswap-finance/welcome/foxswap-tokens/glayer',
  FOXSWAP_BONDS: 'https://docs.foxchain.app/foxswap-finance/product-and-features/raise/treasury-bills',
  NFT: 'https://docs.foxchain.app/foxswap-finance/product-and-features/collect/non-fungible-foxs-nfas',
  AUCTION:
    'https://docs.foxchain.app/foxswap-finance/product-and-features/collect/non-fungible-foxs-nfas/nfa-auction-house',
  STAKING: 'https://docs.foxchain.app/foxswap-finance/product-and-features/stake',
  FOXSTATS: 'https://docs.foxchain.app/foxswap-finance/product-and-features/foxstats',
  PROTOCOL_DASHBOARD: 'https://docs.foxchain.app/foxswap-finance/product-and-features/protocol-dashboard',
  THE_MIGRATION: 'https://docs.foxchain.app/foxswap-finance/product-and-features/stake/hard-cap-migration-masterfoxv2',
}
