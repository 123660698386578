import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'FoxSwap : Your One-Stop DeFi Hub',
  description:
    'FoxSwap is a DeFi Hub on BNB Chain, Polygon, & Ethereum. Swap, stake, and lend cryptocurrencies, from stablecoins to altcoins - all in one place.',
  image: 'https://foxdex.finance/logo.png',
}

export const customMeta: { [key: string]: PageMeta } = {
  '/': {
    title: 'Home Page | FoxSwap: Your One-Stop DeFi Hub',
    description:
      'FoxSwap is a DeFi Hub on BNB Chain, Polygon, & Ethereum. Swap, stake, and lend cryptocurrencies, from stablecoins to altcoins - all in one place.',
  },
  '/swap': {
    title: 'Swap | FoxSwap : Your One-Stop DeFi Hub',
    description: 'Swap between hundreds of crypto tokens using our decentralized exchange.',
  },
  '/foxstats': {
    title: 'FoxStats | FoxSwap : Your One-Stop DeFi Hub',
    description: 'Track your portfolio on FoxSwap using our custom dashboard.',
  },
  '/nft': {
    title: 'Non Fungible Apes | FoxSwap : Your One-Stop DeFi Hub',
    description: 'Buy and sell FoxSwap NFTs and join our NFT community.',
  },
  '/farms': {
    title: 'FOXLAYER Farms | FoxSwap : Your One-Stop DeFi Hub',
    description: 'Stake your liquidity provider (LP) tokens in FOXLAYER Farms to earn FOXLAYER.',
  },
  '/jungle-farms': {
    title: 'Jungle Farms | FoxSwap : Your One-Stop DeFi Hub',
    description: 'Stake your liquidity provider (LP) tokens to earn partner project tokens.',
  },
  '/pools': {
    title: 'Staking Pools | FoxSwap : Your One-Stop DeFi Hub',
    description: 'Stake FOXLAYER in Staking Pools to earn partner project tokens.',
  },
  '/liquidity': {
    title: 'Liquidity | FoxSwap : Your One-Stop DeFi Hub',
    description: 'Add liquidity to the FoxSwap decentralized exchange to create LPs and earn trading fees.',
  },
  '/add-liquidity': {
    title: 'Liquidity | FoxSwap : Your One-Stop DeFi Hub',
    description: 'Add liquidity to the FoxSwap decentralized exchange to create LPs and earn trading fees.',
  },
  '/iao': {
    title: 'Initial Ape Offerings | FoxSwap : Your One-Stop DeFi Hub',
    description: 'Launch your crypto project with FoxSwap, or commit into Initial Ape Offerings.',
  },
  '/glayer': {
    title: 'GLAYER | FoxSwap : Your One-Stop DeFi Hub',
    description: 'Convert your FOXLAYER to GLAYER to gain exclusive access to governance, pools, and more.',
  },
  '/maximizers': {
    title: 'FOXLAYER Maximizers | FoxSwap : Your One-Stop DeFi Hub',
    description: 'Stake your liquidity provider (LP) tokens in auto-compounding vaults to earn FOXLAYER.',
  },
  '/auction': {
    title: 'Auction | FoxSwap : Your One-Stop DeFi Hub',
  },
  '/staking': {
    title: 'NFA Staking | FoxSwap : Your One-Stop DeFi Hub',
  },
  '/bonds': {
    title: 'FoxSwap Bonds | FoxSwap : Your One-Stop DeFi Hub',
    description: 'Get FOXLAYER and partner project tokens at a discount using your liquidity provider (LP) tokens.',
  },
  '/limit-orders': {
    title: 'Limit Orders | FoxSwap : Your One-Stop DeFi Hub',
    description: 'Trade crypto tokens at the price you want using limit orders on the FoxSwap DEX.',
  },
}
